<template>
  <div class="white-box">
    <main class="card invision-card elevate-2 borderless container padding">
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <div class="table-header">
            <span class="title">{{ title }} {{ protocolo }}</span>
            <b-button
              :disabled="existeMaterialNaoAbortavel()"
              type="button"
              variant="none"
              id="button"
              class="text-white primary-contained-button"
              @click="abortarProtocolo"
            >ABORTAR TODO O PROTOCOLO</b-button>
          </div>
          <b-table
            hover
            outlined
            :busy="isBusy"
            :fields="tableFields"
            :items="materiais"
            class="mt-3"
            responsive
            show-empty
            :empty-text="errorMsg || 'Não existem materiais selecionados'"
          >
            <template #cell(state)="{ item }">
              <b-button
                :disabled="item._rowVariant !== null"
                class="btn btn-sm text--black"
                variant="outline-dark"
                @click="openRemoveModal(item)"
              >
                <trash-2-icon v-if="!item._rowVariant" class="w20px" />

                <alert-circle-icon
                  v-else
                  class="w20px mx-auto"
                  v-b-tooltip.hover title="O processo desse material não é abortável pois seu ciclo já avançou.">
                </alert-circle-icon>

              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </main>
    <Modal
      id="modalRemoveProtocolo modal-sm"
      ref="modalRemoveProtocolo"
      title="Abortar protocolo inteiro"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <AbortoProtocoloInteiroModal
        title="Abortar protocolo inteiro"
        :protocolo="protocolo"
        :materiais="materiais"
      />
    </Modal>

    <Modal
      id="modalRemoveProcesso modal-sm"
      ref="modalRemoveProcesso"
      title="Abortar Processo do Protocolo"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <AbortoProcessoMaterialID v-if="processos.shownId !== '-'" :item='processos'/>

      <AbortoProcessoMaterialGenerico v-else :item="processos" />

    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Utils/Modal';
import AbortoProtocoloInteiroModal from './AbortoProtocoloInteiroModal';
import AbortoProcessoMaterialID from './AbortoProcessoModal';
import AbortoProcessoMaterialGenerico from '../MaterialSemQRCode/components/AbortoProcessoModal';

export default {
  components: {
    Modal,
    AbortoProtocoloInteiroModal,
    AbortoProcessoMaterialID,
    AbortoProcessoMaterialGenerico,
  },

  data() {
    return {
      tableFields: [
        {
          key: 'tipoMaterial',
          label: 'Tipo Material',
        },
        {
          key: 'shownId',
          label: 'ID',
        },
        {
          key: 'descricao',
          label: 'Descrição',
        },
        {
          key: 'qtd',
          label: 'Qtd',
        },
        {
          key: 'subSetor',
          label: 'Área',
        },
        {
          key: 'tipoProcesso',
          label: 'Processo',
        },
        {
          key: 'date',
          label: 'Data',
        },
        {
          key: 'state',
          label: 'Abortar',
        },
      ],
      processos: {},
    };
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    materiais: {
      type: Array,
      required: true,
    },
    protocolo: {
      type: String,
      required: true,
    },
    errorMsg: {
      type: String,
      required: true,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    existeMaterialNaoAbortavel() {
      return (this.materiais.find((material) => material._rowVariant));
    },
    async abortarProtocolo() {
      if (!this.materiais.length) {
        await swal({
          title: 'Ops',
          text: 'Nenhum material selecionado',
          icon: 'warning',
          button: 'Continuar...',
        });
        return;
      }
      this.$refs.modalRemoveProtocolo.show();
    },
    openRemoveModal(item) {
      this.processos = item;
      this.$refs.modalRemoveProcesso.show();
    },
  },
};
</script>

<style scoped>
  .white-box {
    margin-top: 2rem;
  }
  .card.invision-card.borderless {
    border: 0;
    padding: 2rem;
  }
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #35384D;
  }
  .table-header {
    display: flex;
    justify-content: space-between;
  }
</style>
