<template>
  <main class="container">
    <div class="w">
      <b-row>
        <b-col>
          <span class="sub-title"
            >Deseja realmente realizar aborto do processo a seguir ?</span
          >
          <div class="box">
            <ul>
              <li>Descrição: {{ item.descricao || item.material.descricao}}</li>
              <li>ID: {{ item.shownId || item.material.shownId}}</li>
              <li>Área: {{ item.area || item.subSetor}} / {{ item.processo || item.tipoProcesso}}</li>
              <li>Data: {{ item.date }}</li>
            </ul>
          </div>
          <br />
          <div>
            <b-form-group label="Justificativa">
              <b-form-input
                v-model="observacaoAbortoProcesso"
                size="lg"
                :state="Boolean(observacaoAbortoProcesso)"
              >
              </b-form-input>
            </b-form-group>
          </div>
          <Button
            id="button"
            @click="removeProcess()"
            text="Confirmar"
            :loading="isBusy"
          />
        </b-col>
      </b-row>
    </div>
  </main>
</template>

<script>
import GenericApi from '@/services/genericRequest';
import Button from '@/components/Utils/Button';

export default {
  components: {
    Button,
  },
  data() {
    return {
      observacaoAbortoProcesso: '',
      isBusy: false,
    };
  },
  props: {
    item: {
      type: Object,
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    async removeProcess() {
      if (!this.observacaoAbortoProcesso) {
        await swal({
          title: 'Erro',
          text: 'Justificativa é obrigatória para aborto de processo!',
          icon: 'error',
          button: 'Continuar...',
        });

        return;
      }
      this.isBusy = true;
      try {
        await GenericApi.update(
          this.item.id_processo_ciclo,
          {
            observacaoAbortoProcesso: this.observacaoAbortoProcesso,
            tipoProcesso: this.item.processoChave,
            id_ciclo: this.item.id_ciclo,
            material: this.item.material,
          },
          'ciclo/abortingProcess',
        );
        this.$router.go();
      } catch (e) {
        console.log(e);
        swal({
          icon: 'error',
          text: 'Erro ao abortar lote. Contacte o suporte',
          button: { confirm: 'Ok...' },
        });
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style scoped>
* {
  font-weight: 400;
  font-size: 16px;
}

.box {
  border: 1px dashed #afb4d6;
  border-radius: 5px;
  padding: 2rem 0 1rem 2rem;
}

.box li {
  list-style: none;
  margin-bottom: 0.5rem;
}

#button {
  margin-top: 2rem;
  float: right;
}

.sub-title {
  font-size: 20px;
  display: flex;
  margin-top: -1rem;
  margin-bottom: 2rem;
  max-width: 300px;
  color: #21cca9;
  text-align: center;
  margin-left: 12%;
}

li + li {
  margin-bottom: 0.5rem;
}

.padding {
  padding: 36px;
  font-weight: 400;
}

label {
  margin-bottom: 3px;
}

.info {
  margin-bottom: 12px;
}

.underline {
  flex-grow: 1;
  /* width: 100%; */
  border-bottom: 1px solid black;
  margin-left: 5px;
  margin: 0 10px 4px 5px;
}

.div-assinaturas {
  display: flex;
  margin-bottom: 10px;
}

.w h2 {
  font-size: 16px;
  font-weight: 600;
}

.modal-header {
  border-bottom: 0;
}

.header .title {
  font-weight: 500;
  font-size: 18px;
  margin-top: -30px;
  margin-bottom: 40px;
  margin-left: -10px;
}
</style>
