<template>
  <AbortoProcesso
    title="Aborto de processos"
    :filters="filters"
    @filtrarUnidade="changeUnidade($event)"
    @filtrarSubSetor="changeSubSetor($event)"
    name="AbortoProcessos"
  >
    <template #filters>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group label="Descrição">
              <b-form-input
                v-model="filters.descricao"
                autocomplete="off"
                class="invision-input sm"
                :disabled="Boolean(filters.protocolo || filters.loteEsterilizacao)"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group label="QR Code">
              <b-form-input
                v-model="filters.code"
                autocomplete="off"
                class="invision-input sm"
                :disabled="Boolean(filters.protocolo || filters.loteEsterilizacao)"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group label="ID">
              <b-form-input
                v-model="filters.id"
                autocomplete="off"
                class="invision-input sm"
                :disabled="Boolean(filters.protocolo || filters.loteEsterilizacao)"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group label="Lote de equipamento">
              <b-form-input
                :value="filters.loteEsterilizacao"
                @input="v => handleChangeLoteOuProtocolo('loteEsterilizacao', v)"
                autocomplete="off"
                class="invision-input sm"
                :disabled="Boolean(filters.protocolo)"
                b-tooltip.left=""
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group label="Protocolo">
              <b-form-input
                :value="filters.protocolo"
                @input="v => handleChangeLoteOuProtocolo('protocolo', v)"
                autocomplete="off"
                class="invision-input sm"
                :disabled="Boolean(filters.loteEsterilizacao)"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
  </AbortoProcesso>
</template>
<script>
import AbortoProcesso from './AbortoProcesso';

const setInitialFiltersValues = () => ({
  descricao: '',
  code: '',
  id: '',
  loteEsterilizacao: '',
  unidade: null,
  subSetor: null,
  protocolo: '',
});

export default {
  components: {
    AbortoProcesso,
  },

  data() {
    return {
      filters: setInitialFiltersValues(),
    };
  },
  methods: {
    changeUnidade($event) {
      this.filters.unidade = $event;
    },

    changeSubSetor($event) {
      this.filters.subSetor = $event;
    },

    handleChangeLoteOuProtocolo(prop, v) {
      this.filters = {
        ...setInitialFiltersValues(),
        [prop]: v,
      };
    },
  },
};
</script>
